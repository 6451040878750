import { render, staticRenderFns } from "./OptiProductStatusFilter.vue?vue&type=template&id=3f667fd4&scoped=true&lang=pug&"
import script from "./OptiProductStatusFilter.vue?vue&type=script&lang=js&"
export * from "./OptiProductStatusFilter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f667fd4",
  null
  
)

export default component.exports