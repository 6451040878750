<template lang="pug">
    .col-xs-12
        .panel.panel-default
            .panel-body
                .col-sm-3.col-xs-1.switch-col
                    .switch-index(v-if="navi.prev"
                        @click="$state.go('app.catalog.product', {id: navi.prev})")
                        i.fa.fa-3x.fa-chevron-left.text-muted(:title="'product.navi.PREV'|translate")

                .col-sm-6.col-xs-10.text-center
                    .main-photo-container
                        span(style="cursor:pointer" @click="dialog.preview = true")
                            img(:src="'img/temp_product.png'"
                                v-if="product.temp_product"
                                style="position: absolute; z-index: 1; opacity: 0.7; max-width: 35px;")
                            img(:src="'img/new_product.png'"
                                v-if="product.new_product && [0, 1].includes(product.type) && !product.temp_product"
                                style="position: absolute; z-index: 1; opacity: 0.7; max-width: 50px;")
                            img.main-photo(:src="'thumb/product/ids/' + product.id + '.jpg'")

                            MountingPortal(v-if="dialog.preview" mountTo="#vue-modal" append)
                                OptiDialogProductPhotoPreview(
                                    :product="product"

                                    @closed="dialog.preview = false")

                    div(v-for="(item, key) in layout")
                        div(v-show="item.type === 'object'")
                            a.link(:href="$state.href('app.layout.grid', {open: item.id, departmentId: departmentId})"
                                target="_blank") {{key + 1}}. {{item.object}} kol. {{item.column + 1}}
                        div(v-show="item.type === 'supply'")
                            a.link(:href="$state.href('app.layout.supply', {})" target="_blank"
                            ) {{key + 1}}. Zapas {{item.object ? item.object : 'salonu'}}

                .col-sm-3.col-xs-1.switch-col
                    .switch-index(v-if="navi.next"
                        @click="$state.go('app.catalog.product', {id: navi.next})")
                        i.fa.fa-3x.fa-chevron-right.text-muted(:title="'product.navi.NEXT'|translate")

                .col-xs-12(style="margin-top:10px;")
                    .photo-container.text-center
                        .photo(v-for="(variant, index) in variants" :key="index")
                            a.link(:href="$state.href('app.catalog.product', {id: variant})")
                                img.variant-photo.zoom(:src="'https://salony.eopti.pl/thumb/product/ids/' + variant + '.jpg'")

                .col-xs-12
                    .pull-left
                        OptiButton(style="margin-left:5px" type="info"
                            v-show="$uac.permission('products.edit')"
                            @click.native="$state.go('app.catalog.product-edit', {id: product.id})"
                            ) {{'catalog.breadcrumb.EDIT'|translate}}
                            template(#icon)
                                i.fa.fa-edit

                    .pull-right
                        OptiButton(style="margin-right:5px" type="info"
                            v-if="!hide" @click.native="dialog.report = true") {{'catalog.product.report.BTN'|translate}}
                            template(#icon)
                                i.fa.fa-exclamation

                            MountingPortal(v-if="dialog.report" mountTo="#vue-modal" append)
                                OptiDialogProductReport(
                                    mode="report"

                                    :product="product"
                                    :department-id="departmentId"

                                    @closed="dialog.report = false")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDialogProductPhotoPreview
        from '@/js/app/vue/components/Warehouse/Products/Details/Dialogs/OptiDialogProductPhotoPreview'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton.vue'
    import OptiDialogProductReport
        from '@/js/app/vue/components/Warehouse/Products/Details/Dialogs/OptiDialogProductReport'

    export default {
        name: 'OptiProductPhoto',
        components: {
            OptiDialogProductPhotoPreview,
            OptiButton,
            OptiDialogProductReport
        },
        props: {
            product: {
                type: Object,
                required: true
            },
            departmentId: {
                type: Number,
                required: true
            },
            layout: {
                type: Array,
                required: true
            },
            hide: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                variants: [],
                navi: {},
                dialog: {
                    preview: false,
                    report: false
                }
            }
        },
        mounted() {
            this.getVariants()
            this.getNavi()
        },
        methods: {
            getVariants() {
                API.post('catalog/product/getVariants', {
                    name: this.product.name,
                    id: this.product.id,
                    catalog: this.product.customerProduct.categoryId
                }).then(res => {
                    this.variants = res.data
                })
            },
            getNavi() {
                API.post('warehouse/product/navi', {
                    lp: this.product.lp,
                    product_name: this.product.name,
                    catalog: this.product.customerProduct.categoryId
                }).then(res => {
                    this.navi = res.data
                }).catch(() => {
                    this.$notify.error('product.navi.notify.error.LOAD')
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .switch-index {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .switch-col {
        margin-top: 70px;
    }

    .aligned-row {
        display: flex;
        position: relative;
    }

    .photo {
        display: inline-flex;
        margin-left:2px;
        margin-bottom:2px;
    }

    .variant-photo {
        width: 100px;
        border-radius: 4px;
    }

    .main-photo {
        height: 150px;
        border-radius: 8px;
    }

    .zoom {
        transition: transform .5s;
    }

    .zoom:hover {
        transform: scale(2);
    }
</style>