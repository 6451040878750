<template lang="pug">
    .product
        #btn-group-patient-detail.row(style="margin-bottom:10px" v-if="!loading.main")
            .col-md-4.text-left.text-center-sm.text-center-xs.text-left-lg
                OptiButton(:title="'catalog.product.buttons.back.CATALOG'|translate"
                    @click.native="$state.go('app.catalog.category', {id: previous.catalog_id, page: previous.page, productId: previous.product_id, departmentId: department_id})"
                    :disabled="loading.previous") {{'catalog.product.buttons.back.CATALOG'|translate}}
                    template(#icon)
                        i.fa.fa-arrow-left

                OptiButton(style="display:block;margin-top:15px" v-show="offer_page"
                    :title="'catalog.product.buttons.back.OFFER'|translate"
                    @click.native="$state.go('app.catalog.offer', {page: offer_page})"
                    ) {{'catalog.product.buttons.back.OFFER'|translate}}
                    template(#icon)
                        i.fa.fa-arrow-left

            .col-md-4.text-center(v-if="!hide")
                OptiButton(type="success" :title="'catalog.product.buttons.ADDTICKET'|translate"
                    v-show="!loading.ticket && !ticket_exists" @click.native="addTicket"
                    ) {{'catalog.product.buttons.ADDTICKET'|translate}}
                    template(#icon)
                        i.fa.fa-ticket
                OptiButton(type="danger" :title="'catalog.product.buttons.DELETETICKET'|translate"
                    v-show="!loading.ticket && ticket_exists" @click.native="deleteTicket"
                    ) {{'catalog.product.buttons.DELETETICKET'|translate}}
                    template(#icon)
                        i.fa.fa-ticket

            .col-md-4.text-center-sm.text-center-xs.text-right-md.text-right-lg(v-if="product.breads[0].id !== 66")
                OptiCartButton(type="default" :product="product")

        .row(v-if="!loading.main")
            OptiProductPhoto(
                :department-id="department_id"
                :layout="layout"
                :product="product"
                :hide="hide")
        OptiDimmer(:active="loading.layout")

        .row(v-if="!loading.main")
            .col-sm-6
                .panel.panel-primary
                    .panel-heading
                        i.fa.fa-navicon.mr3
                        span {{'catalog.product.properties.PROPERTIES'|translate}}

                    .table-responsive
                        table.table.table-striped
                            tbody
                                tr(v-show="product.expired")
                                    td.text-center.warn.bold(colspan="2")
                                        i.fa.fa-trash.mr3
                                        span {{'product.EXPIRED'|translate}}
                                tr
                                    td Id
                                    td {{product.id}}
                                tr
                                    td {{'catalog.product.properties.BARCODE'|translate}}
                                    td
                                        i.fa.fa-fw.fa-barcode.mr3
                                        span {{product.barcode}}
                                tr
                                    td {{'catalog.product.properties.NAME'|translate}}
                                    td
                                        i.fa.fa-fw.fa-info.mr3
                                        span {{product.name}}
                                tr(v-show="loading.first_delivery || first_delivery")
                                    td {{'catalog.product.properties.FIRSTDELIVERY'|translate}}
                                    td
                                        i.fa.fa-cog.fa-spin(v-if="loading.first_delivery")
                                        span(v-else) {{first_delivery}}
                                tr
                                    td {{'catalog.product.properties.STOCK'|translate}}
                                    td
                                        OptiDepositoryInfo(
                                            v-if="!(loading.ticket || loading.history.depository)"
                                            :product="product")
                                        span(v-show="inTransitVisibility")
                                            span.text-muted.mr3 |
                                            i.fa.fa-fw.fa-road.text-gray-light.mr3
                                            span.text-expressive {{in_transit}}
                                        i.fa.fa-cog.fa-spin(v-show="loading.ticket || loading.history.depository")
                                tr(v-show="permission.edit")
                                    td {{'catalog.product.properties.WAREHOUSE'|translate}}
                                    td
                                        span.mr3(:title="'catalog.product.stock.STOCK'|translate|lower") {{product.stock}}
                                        span.text-muted.mr3 |
                                        span.mr3(:title="'catalog.product.stock.THRESHOLD'|translate|lower") {{product.threshold}}
                                        span.text-muted.mr3 |
                                        i.fa.fa-check(v-show="product.inStock" :title="'catalog.product.stock.INSTOCK'|translate|lower")
                                        i.fa.fa-remove.warn(v-show="!product.inStock" :title="'catalog.product.stock.INSTOCK'|translate|lower")

                                tr
                                    td {{'catalog.product.sum.HEADING'|translate}}
                                    td
                                        span(v-show="!loading.sum_departments" :class="{'link': sum_departments_sigma}"
                                            @click="openSumDepartments") Σ {{sum_departments_sigma}}
                                            MountingPortal(v-if="dialog.sum_departments" mountTo="#vue-modal" append)
                                                OptiDialogProductSumDepartments(
                                                    :sum-departments="sum_departments"
                                                    :sum-departments-sigma="sum_departments_sigma"

                                                    @closed="dialog.sum_departments = false")
                                        i.fa.fa-cog.fa-spin(v-show="loading.sum_departments")

                                tr
                                    td {{'catalog.product.properties.PRICE'|translate}}
                                    td
                                        i.fa.fa-fw.fa-money.mr3
                                        span.mr3 {{product.price|currency}}
                                        OptiProductSpecialPercent(:customerProduct="product.customerProduct")
                                tr(v-show="permission.wholesale")
                                    td {{'catalog.product.properties.WHOLESALE'|translate}}
                                    td
                                        i.fa.fa-fw.fa-money.mr3
                                        span {{product.priceWholesale|currency}}
                                tr(v-show="product.customerProduct.price_omnibus")
                                    td {{'catalog.product.properties.OMNIBUS'|translate}}
                                    td
                                        span.link(@click="dialog.omnibus = true")
                                            i.fa.fa-fw.fa-money.mr3
                                            span {{product.customerProduct.price_omnibus|currency}}

                                        MountingPortal(v-if="dialog.omnibus" mountTo="#vue-modal" append)
                                            OptiDialogProductOmnibus(
                                                :product-id="product.id"

                                                @closed="dialog.omnibus = false")
                                tr(v-show="permission.edit && product.manufacturerId")
                                    td SKU
                                    td {{product.manufacturerId}}

            .col-sm-6
                .col-xs-12(style="padding:0")
                    .panel.panel-primary
                        .panel-heading(style="cursor:pointer" @click="showAttributes = !showAttributes")
                            i.fa.fa-navicon.mr3
                            span {{'catalog.product.ATTRIBUTES'|translate}}

                            .pull-right
                                i.fa(:class="{'fa-arrow-up': !showAttributes, 'fa-arrow-down': showAttributes}")

                        .table-responsive(v-show="showAttributes")
                            table.table.table-striped
                                tbody
                                    tr(v-for="attribute in product.attributes" :key="attribute.id"
                                        v-show="attribute.type.id && (attribute.value || attribute.type.required)")
                                        td {{attribute.type.name}}
                                        td(v-if="attribute.type.valueType === 1") {{attribute.value|attributeSelect(attribute.type.options)}}
                                        td(v-show="attribute.type.valueType === 3")
                                            i.fa.fa-check(v-show="attribute.value === '1'")
                                        td(v-show="![1, 3].includes(attribute.type.valueType)") {{attribute.value}}

                        .panel-footer.text-right(v-show="permission.edit && showAttributes")
                            OptiButton(type="info" :title="'catalog.product.attributes.edit.TITLE'|translate"
                                @click.native="dialog.attributes = true") {{'catalog.product.attributes.edit.EDIT'|translate}}
                                template(#icon)
                                    i.fa.fa-edit

                                MountingPortal(v-if="dialog.attributes" mountTo="#vue-modal" append)
                                    OptiDialogProductAttributes(
                                        :product="product"

                                        @update-attributes="updateAttributes"
                                        @closed="dialog.attributes = false")

                .col-xs-12(style="padding:0")
                    OptiProductSpareParts(:product_id="product.id")

                .col-xs-12(style="padding:0" v-if="[0, 1].includes(product.type)")
                    OptiProductStyles(
                        :product-id="product.id"

                        @styles-were-changed="renderWarehouseLogs")

            .col-xs-12(v-if="permission.statistic_chart")
                OptiProductStatisticChart(
                    :product="product"
                    :sum_reclamations="sum_reclamations"

                    @out_of_production="product.outOfProduction = 1"
                    @scroll="goToSection")

            .col-xs-12
                OptiProductDetailsInfoFromProducent(:product-id="product.id")

            .col-xs-12(v-show="product.type === 0 || product.type === 1")
                .panel.panel-primary(style="position:relative")
                    .panel-heading(@click="toggle = !toggle" style="cursor:pointer")
                        i.fa.fa-navicon.mr3
                        span {{'catalog.product.MEASUREMENT'|translate}}

                        .pull-right
                            i.fa(:class="{'fa-arrow-up': !toggle, 'fa-arrow-down': toggle}")

                    .panel-body(v-show="toggle")
                        .row
                            .col-md-2.col-xs-6
                                .form-group
                                    label(for="pdR") {{'prescription.PDDISTANCE'|translate}} - {{'prescription.RIGHT'|translate}}
                                    OptiNumber#pdR(:header="$filters.translate('prescription.PDDISTANCE') + ' - ' + $filters.translate('prescription.RIGHT')"
                                        max="40" :value="preview.pdR" @confirmed="pdR => updatePreview('pdR', pdR)") {{preview.pdR}}
                                .form-group
                                    label(for="btR") {{'tasks.BT'|translate}} - {{'prescription.RIGHT'|translate}}
                                    OptiNumber#btR(:header="$filters.translate('tasks.BT') + ' - ' + $filters.translate('prescription.RIGHT')"
                                        max="40" :value="preview.btR" @confirmed="btR => updatePreview('btR', btR)") {{preview.btR}}
                            .col-md-2.col-md-push-8.col-xs-6
                                .form-group
                                    label(for="pdL") {{'prescription.PDDISTANCE'|translate}} - {{'prescription.LEFT'|translate}}
                                    OptiNumber#pdL(:header="$filters.translate('prescription.PDDISTANCE') + ' - ' + $filters.translate('prescription.LEFT')"
                                        max="40" :value="preview.pdL" @confirmed="pdL => updatePreview('pdL', pdL)") {{preview.pdL}}
                                .form-group
                                    label(for="btL") {{'tasks.BT'|translate}} - {{'prescription.LEFT'|translate}}
                                    OptiNumber#btL(:header="$filters.translate('tasks.BT') + ' - ' + $filters.translate('prescription.LEFT')"
                                        max="40" :value="preview.btL" @confirmed="btL => updatePreview('btL', btL)") {{preview.btL}}

                            .col-md-8.col-md-pull-2
                                a(:href="'api/oma/frame/' + product.id + '.jpg?pdR=' + preview.pdR + ',00&btR=' + preview.btR + ',00&pdL=' + preview.pdL + ',00&btL=' + preview.btL + ',00'" target="_blank")
                                    img.img-responsive(:src="'api/oma/frame/' + product.id + '.jpg?pdR=' + preview.pdR + ',00&btR=' + preview.btR + ',00&pdL=' + preview.pdL + ',00&btL=' + preview.btL + ',00'"
                                        @load="loading.oma.preview = false")

                    OptiDimmer(:active="loading.oma.main || loading.oma.preview")

            .col-xs-12
                OptiProductDetailsDepositoryHistory(
                    :product="product"
                    :department-id="department_id"
                    :loading="loading.history.depository"

                    @reported="reported"
                    @update-department="updateDepartment")

            .col-xs-12(v-if="permission.history && product && render.warehouse_logs")
                OptiProductWarehouseHistory(:product="product" @correctWarehouse="correctWarehouse")

            .col-xs-12(v-if="permission.reports" ref="task_sale")
                OptiProductDetailsReportTaskSale

            .col-xs-12(ref="reclamation")
                OptiProductDetailsReportReclamationsRegenerations

        OptiDimmer(:active="loading.main")
</template>

<script>
import {API} from '@/js/app/vue/api'

import OptiButton from '@/js/app/vue/components/Button/OptiButton'
import OptiCartButton from '@/js/app/vue/components/Carts/OptiCartButton'
import OptiDepositoryInfo from '@/js/app/vue/components/Warehouse/Products/OptiDepositoryInfo'
import OptiDialogProductSumDepartments
    from '@/js/app/vue/components/Warehouse/Products/Details/Dialogs/OptiDialogProductSumDepartments'
import OptiDialogProductOmnibus
    from '@/js/app/vue/components/Warehouse/Products/Details/Dialogs/OptiDialogProductOmnibus'
import OptiProductSpecialPercent from '@/js/app/vue/components/Warehouse/Products/OptiProductSpecialPercent'

import OptiDialogProductReport
    from '@/js/app/vue/components/Warehouse/Products/Details/Dialogs/OptiDialogProductReport'
import OptiDialogProductAttributes
    from '@/js/app/vue/components/Warehouse/Products/Details/Dialogs/OptiDialogProductAttributes'

import OptiProductDetailsDepositoryHistory
    from '@/js/app/vue/components/Warehouse/Products/Details/OptiProductDetailsDepositoryHistory'
import OptiProductWarehouseHistory
    from '@/js/app/vue/components/Warehouse/Products/OptiProductWarehouseHistory'

import OptiProductDetailsReportTaskSale
    from '@/js/app/vue/components/Warehouse/Products/Details/OptiProductDetailsReportTaskSale'
import OptiProductDetailsReportReclamationsRegenerations
    from '@/js/app/vue/components/Warehouse/Products/Details/OptiProductDetailsReportReclamationsRegenerations'

import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'
import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
import OptiProductStatisticChart
    from '@/js/app/vue/components/Warehouse/Products/Details/OptiProductStatisticChart'
import OptiProductStatistic from '@/js/app/vue/components/Warehouse/Products/Details/OptiProductStatistic'
import OptiProductDetailsInfoFromProducent
    from '@/js/app/vue/components/Warehouse/Products/Details/OptiProductDetailsInfoFromProducent'
import OptiProductSpareParts from '@/js/app/vue/components/Warehouse/Products/Details/OptiProductSpareParts'
import OptiProductStyles from '@/js/app/vue/components/Warehouse/Products/Details/OptiProductStyles'
import OptiProductPhoto from '@/js/app/vue/components/Warehouse/Products/Details/OptiProductPhoto'
import OptiProductAvailabilityChart
    from '@/js/app/vue/components/Warehouse/Products/Details/OptiProductAvailabilityChart'

export default {
    name: 'OptiProductDetails',
    components: {
        OptiProductAvailabilityChart,
        OptiProductPhoto,
        OptiProductSpareParts,
        OptiProductStyles,
        OptiButton,
        OptiCartButton,
        OptiProductStatistic,
        OptiProductStatisticChart,
        OptiProductDetailsInfoFromProducent,
        OptiDepositoryInfo,
        OptiDialogProductSumDepartments,
        OptiDialogProductOmnibus,
        OptiProductSpecialPercent,

        OptiDialogProductReport,
        OptiDialogProductAttributes,

        OptiProductDetailsDepositoryHistory,
        OptiProductWarehouseHistory,

        OptiProductDetailsReportTaskSale,
        OptiProductDetailsReportReclamationsRegenerations,

        OptiNumber,
        OptiDimmer
    },
    data() {
        return {
            toggle: false,
            showAttributes: false,
            product: null,
            previous: {},
            layout: [],

            sum_departments: [],
            sum_departments_sigma: 0,
            sum_reclamations: 0,
            in_transit: 0,

            department_id: this.$state.params.departmentId && this.$state.params.departmentId !== '49' ?
                parseInt(this.$state.params.departmentId) : this.$uac.user.activeDepartmentId,

            ticket_exists: false,

            first_delivery: null,

            preview: {
                pdR: 32,
                pdL: 32,
                btR: 0,
                btL: 0
            },

            dialog: {
                sum_departments: false,
                report: false,
                attributes: false,
                omnibus: false
            },

            hide: ['SłS', 'Katalog'].includes(this.$uac.user.activeRole.short),
            permission: {
                edit: this.$uac.permission('products.edit'),
                history: this.$uac.permission('products.history'),
                statistic_chart: this.$uac.permission('products.statistic_chart'),
                reports: this.$uac.permission('products.reports'),
                wholesale: this.$uac.user.activeRole.admin || this.$uac.user.activeDepartmentId === 49 ||
                    this.$uac.user.id === 747 || // libor na życzenie Agi
                    ([16, 17].includes(this.$uac.user.activeRole.id) && [3, 4].includes(this.$uac.user.departmentType))

                //ten sam warunek jest w OptiProductInfo
            },
            render: {
                warehouse_logs: true
            },
            loading: {
                main: true,
                previous: true,
                ticket: true,
                layout: true,
                sum_departments: true,
                first_delivery: true,
                oma: {
                    main: true,
                    preview: true
                },
                history: {
                    depository: true,
                    warehouse: true
                }
            }
        }
    },
    beforeMount() {
        this.$store.dispatch('offer/init')
    },
    mounted() {
        API.get('catalog/product/' + this.$state.params.id).then(res => {
            this.product = res.data

            this.loadPrevious()
            this.loadTicket()
            this.loadOma()
            this.loadStock()
            this.loadSumDepartments()
            this.loadReclamations()
            this.loadInTransit()
            this.getDeliveryDate()

            this.$emit('update-memory', {
                id: this.product.id,
                name: this.product.name
            })

            this.setUrl()
        }).finally(() => {
            this.loading.main = false
        }).catch(() => {
            this.$notify.error('catalog.product.notify.error.LOAD')
        })
    },
    methods: {
        getDeliveryDate() {
            API.get('catalog/product/firstDelivery/' + this.$state.params.id).then(res => {
                this.first_delivery = res.data
            }).finally(() => {
                this.loading.first_delivery = false
            }).catch(() => {
                this.$notify.error('catalog.product.notify.error.FIRST_DELIVERY')
            })
        },
        goToSection(sectionRef) {
            const el = this.$refs[sectionRef]

            if (el) {
                el.scrollIntoView({behavior: 'smooth'})
            }
        },
        loadInTransit() {
            API.get('warehouse/product/getInTrasit/' + this.$state.params.id).then(res => {
                this.in_transit = res.data
            }).catch(() => {
                this.$notify.error('catalog.product.notify.error.INTRANSIT')
            })
        },
        loadReclamations() {
            API.get('warehouse/product/sumReclamations/' + this.$state.params.id).then(res => {
                this.sum_reclamations = res.data
            }).catch(() => {
                this.$notify.error('catalog.product.notify.error.RECLAMATIONS')
            })
        },
        loadPrevious() {
            if (this.product.breads.length) {
                let filter = JSON.parse(window.localStorage.getItem('category-products-filter')),
                    statuses = JSON.parse(window.localStorage.getItem('category-products-selection')),
                    sorting = JSON.parse(window.localStorage.getItem('category-products-sorting'))

                API.post('warehouse/products/page', {
                    product_id: this.product.id,
                    catalog_id: this.product.breads[this.product.breads.length - 1].id,
                    filter: filter ? filter : {local: false, main: false},
                    statuses: statuses ? statuses : {
                        all: true,
                        mh: true,
                        pi1: true,
                        pi: true,
                        ac: true,
                        po: true,
                        not_selected: true
                    },
                    sorting: sorting ? sorting : {
                        asc: true,
                        sortBy: 'lp'
                    }
                }).then(res => {
                    if (res.data) {
                        this.product.breads.forEach((bread, key) => {
                            if (key === (this.product.breads.length - 1)) {
                                bread.page = res.data
                                bread.product_id = this.product.id

                                this.previous = {
                                    catalog_id: bread.id,
                                    page: bread.page,
                                    product_id: bread.product_id
                                }
                            } else {
                                bread.page = 1
                            }
                        })
                    }

                    this.$emit('update-breadcrumb', this.product.breads, this.department_id)
                }).finally(() => {
                    this.loading.previous = false
                }).catch(() => {
                    this.$notify.error('catalog.product.notify.error.PAGECATALOG')
                })
            }
        },
        loadTicket() { //TODO: Dobry pomysł by zmienić to na vuex
            API.get('warehouse/ticket/storage/exists/' + this.product.id).then(res => {
                this.ticket_exists = res.data.exists
            }).finally(() => {
                this.loading.ticket = false
            }).catch(() => {
                this.$notify.error('ticket.service.notify.error.LOAD')
            })
        },
        loadOma() {
            API.get('oma/frame/' + this.product.id + '.fi').then(res => {
                if (res.data) {
                    this.preview.btR = Math.ceil(res.data.height / 2)
                    this.preview.btL = Math.ceil(res.data.height / 2)
                }
            }).finally(() => {
                this.loading.oma.main = false
            }).catch(() => {
                this.$notify.error('catalog.product.notify.error.OMA')
            })
        },
        loadLayout() {
            this.loading.layout = true

            API.get('layout/product/info/' + this.department_id + '/' + this.product.id).then(res => {
                this.layout = res.data
            }).finally(() => {
                this.loading.layout = false
            }).catch(() => {
                this.$notify.error('catalog.product.notify.error.LAYOUT')
            })
        },
        loadStock() {
            this.loading.history.depository = true

            API.post('catalog/product/depository', {
                product_id: this.product.id,
                department_id: this.department_id
            }).then(res => {
                this.product.depository = res.data

                this.loadLayout()
            }).finally(() => {
                this.loading.history.depository = false
            }).catch(() => {
                this.$notify.error('catalog.product.notify.error.CHANGEHISTORY')
            })
        },
        loadSumDepartments() {
            this.loading.sum_departments = true

            API.get('warehouse/product/' + this.product.id + '/sum-departments').then(res => {
                this.sum_departments = res.data
                this.sum_departments_sigma = _.sumBy(this.sum_departments, department => {
                    if (department.stock > 0) {
                        return department.stock
                    }
                })

                if (typeof this.sum_departments_sigma === 'undefined') {
                    this.sum_departments_sigma = 0
                }
            }).finally(() => {
                this.loading.sum_departments = false
            }).catch(() => {
                this.$notify.error('catalog.product.sum.notify.error.LOAD')
            })
        },
        setUrl() {
            this.$state.go('app.catalog.product', {
                id: this.product.id,
                departmentId: this.department_id
            }, {
                notify: false
            })
        },
        addTicket() {
            this.loading.ticket = true

            API.post('warehouse/ticket/storage/add', {
                product_id: this.product.id,
                product_name: this.product.name,
                barcode: this.product.barcode,
                price: this.product.price
            }).then(() => {
                this.ticket_exists = true
            }).finally(() => {
                this.loading.ticket = false
            }).catch(() => {
                this.$notify.error('ticket.service.notify.error.ADD')
            })
        },
        deleteTicket() {
            this.loading.ticket = true

            API.post('warehouse/ticket/storage/delete', {
                product_id: this.product.id
            }).then(() => {
                this.ticket_exists = false
            }).finally(() => {
                this.loading.ticket = false
            }).catch(() => {
                this.$notify.error('ticket.service.notify.error.DELETE')
            })
        },
        openSumDepartments() {
            if(this.sum_departments_sigma) {
                this.dialog.sum_departments = true
            }
        },
        reported(quantity) {
            this.dialog.report = false

            this.product.stock = quantity

            this.loadStock()
            this.loadSumDepartments()
        },
        updatePreview(field, value) {
            this.preview[field] = value

            this.loading.oma.preview = true
        },
        updateAttributes(attributes, logs) {
            this.product.attributes = attributes
            this.product.logs = logs

            this.dialog.attributes = false
        },
        updateDepartment(department) {
            if (this.department_id !== department.id) {
                this.department_id = department.id

                this.loadStock()
                this.setUrl()

                this.$emit('update-breadcrumb', this.product.breads, this.department_id)
            }
        },
        correctWarehouse(quantity) {
            this.product.stock = quantity
        },
        renderWarehouseLogs() {
            this.render.warehouse_logs = false

            this.$nextTick(() => {
                this.render.warehouse_logs = true
            })
        }
    },
    computed: {
        inTransitVisibility() {
            return this.$uac.user.activeDepartmentId === 91 || this.$uac.user.activeDepartmentId === 49
        },
        offer_page() {
            if(this.$store.getters['offer/getProducts'].map(p => p.id).includes(this.product.id)) {
                return this.$store.getters['offer/getPaginator'].page
            }

            return 0
        }
    }
}
</script>