<template lang="pug">
    OptiDialog.fullscreen
        template(#header)
            i.icon.icon-envelope-open.mr3
            span {{'patient.emails.details.HEADER'|translate}} \#{{mail_id}}

            i.fa.fa-lg.fa-remove.pull-right(style="cursor:pointer;padding:3px" :title="'default.CLOSE'|translate"
                @click="close")

        template(#body)
            .panel-body(v-if="!loading")
                .row.text-center
                    div(:class="{'col-md-3': mails.length === 1, 'col-md-4': mails.length > 1}")
                        b.mr3 {{'patient.emails.table.PATIENT'|translate}}
                        OptiPatient(v-if="mails[0].patient_id" :data="mails[0].patient")
                        span.text-muted(v-else) {{'default.NA'|translate}}

                    div(:class="{'col-md-3': mails.length === 1, 'col-md-4': mails.length > 1}")
                        b.mr3 {{mails[0].ref_type_name|translate}}
                        OptiRefId(section="mail" :type-id="mails[0].ref_type" :id="mails[0].ref_id")

                    div(:class="{'col-md-3': mails.length === 1, 'col-md-4': mails.length > 1}")
                        b.mr3 {{'patient.emails.table.created.BY'|translate}}
                        span {{mails[0].user.firstName}} {{mails[0].user.lastName}}

                    .col-md-3(v-if="mails.length === 1")
                        b.mr3 {{'patient.emails.table.created.AT'|translate}}
                        span {{mails[0].created_at}}

                .row
                    .col-xs-12
                        table#mail-table-header
                            tbody
                                tr(v-if="mails.length === 1")
                                    th {{'patient.emails.table.FROM'|translate}}
                                    td {{mails[0].from}}
                                tr(v-if="mails.length === 1")
                                    th {{'patient.emails.table.TO'|translate}}
                                    td {{mails[0].to}}
                                tr
                                    th {{'patient.emails.table.SUBJECT'|translate}}
                                    td {{mails[0].subject}}

            #body(v-if="!loading" :class="{'panel-multi': mails.length > 1, 'is-ios': iOS}")
                .panel.widget.bg-warning(v-if="mails[0].other_offer_mails && mails[0].other_offer_mails.length")
                    .row.row-table
                        .col-xs-3.text-center.bg-warning-dark.pv-lg
                            i.fa.fa-3x.fa-exclamation-circle
                        .col-xs-9.pv-lg
                            .h3.mt0 {{'patient.emails.details.offer.OTHERS'|translate}}
                            .h4.mt0
                                ul
                                    li(v-for="offer_mail in mails[0].other_offer_mails")
                                        span.link(:title="'default.REFERENCETYPE'|translate" @click="load(offer_mail.id)")
                                            span(style="margin-right:15px") \#{{offer_mail.id}}

                                            span(style="margin-right:15px")
                                                i.fa.fa-arrow-right.mr3
                                                span {{offer_mail.to}}

                                            span
                                                i.fa.fa-calendar.mr3
                                                span {{offer_mail.created_at}}

                .panel-mail(v-for="mail in mails" :key="mail.id")
                    .panel-mail-header(v-if="mails.length > 1"
                        :title="'default.toggle.' + (mail.active ? 'HIDE' : 'SHOW')|translate"
                        @click="mail.active = !mail.active")
                        table
                            tbody
                                tr
                                    th {{'patient.emails.table.FROM'|translate}}
                                    td {{mail.from}}
                                tr
                                    th {{'patient.emails.table.TO'|translate}}
                                    td {{mail.to}}

                        table
                            tbody
                                tr
                                    th {{'patient.emails.table.created.AT'|translate}}
                                tr
                                    td {{mail.created_at}}

                        i.fa.fa-lg(:class="{'fa-arrow-down': mail.active, 'fa-arrow-up': !mail.active}")

                    .panel.widget.bg-danger(v-if="mail.bounced && mail.active")
                        .row.row-table
                            .col-xs-3.text-center.bg-danger-dark.pv-lg
                                i.fa.fa-3x.fa-exclamation-circle
                            .col-xs-9.pv-lg
                                .h3.mt0 {{mail.bounced.header}}
                                .h4.mt0 {{mail.bounced.message}}
                    .panel.widget.bg-primary(v-if="mail.attachment && mail.active")
                        .row.row-table
                            .col-xs-3.text-center.bg-primary-dark.pv-lg
                                i.fa.fa-3x.fa-paperclip
                            .col-xs-9.pv-lg
                                .h3.mt0 {{'patient.emails.attachment.panel.HEADER'|translate}}
                                .h4.mt0
                                    div {{'patient.emails.attachment.panel.info.1'|translate}}
                                    div {{'patient.emails.attachment.panel.info.2'|translate}}

                    iframe(v-show="mail.active" onload='javascript:(function(o){o.style.height=(o.contentWindow.document.body.scrollHeight + 10)+"px"}(this))'
                        scrolling="no" :src="'patients/email/preview/' + mail.id")

            OptiDimmer(:active="loading")

        template(#footer)
            .row
                .col-xs-6.text-left
                    OptiButton(v-if="mails.length && mails[0].ref_type === 1" @click.native="dialog.retry_offer = true"
                        ) {{'patient.emails.details.offer.RETRY'|translate}}
                        template(#icon)
                            i.fa.fa-repeat

                    MountingPortal(v-if="dialog.retry_offer" mountTo="#vue-modal" append)
                        OptiCatalogOfferDialogEMail(
                            :offer="mails[0].offer"
                            :patient="mails[0].patient"

                            @close="retryOfferClosed")

                .col-xs-6
                    OptiButton(@click.native="close") {{'default.OK'|translate}}
                        template(#icon)
                            i.fa.fa-check
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiDialog from '@/js/app/vue/components/OptiDialog'
    import OptiPatient from '@/js/app/vue/components/Patients/OptiPatient'
    import OptiRefId from '@/js/app/vue/components/OptiRefId'
    import OptiCatalogOfferHeader from '@/js/app/vue/components/Catalog/Offer/Header/OptiCatalogOfferHeader'
    import OptiCatalogOfferHeaderProducts
        from '@/js/app/vue/components/Catalog/Offer/Header/OptiCatalogOfferHeaderProducts'
    import OptiProductInfo from '@/js/app/vue/components/Warehouse/Products/OptiProductInfo'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'
    import OptiCatalogOfferBtnMail from '@/js/app/vue/components/Catalog/Offer/OptiCatalogOfferBtnMail'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiCatalogOfferDialogEMail
        from '@/js/app/vue/components/Catalog/Offer/Dialogs/OptiCatalogOfferDialogEMail'

    export default {
        name: 'OptiPatientEmailPreviewDialog',
        components: {
            OptiDialog,
            OptiPatient,
            OptiRefId,
            OptiProductInfo,
            OptiCatalogOfferHeader,
            OptiCatalogOfferHeaderProducts,
            OptiButton,
            OptiCatalogOfferBtnMail,
            OptiDimmer,
            OptiCatalogOfferDialogEMail
        },
        props: {
            id: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                mail_id: null,
                mails: [],

                dialog: {
                    retry_offer: false
                },
                retry_offer: false,
                loading: true
            }
        },
        mounted() {
            document.body.style.overflow = 'hidden'

            this.load(this.id)
        },
        destroyed() {
            document.body.style.overflow = ''
        },
        methods: {
            load(mail_id) {
                this.mail_id = mail_id
                this.loading = true

                API.get('patients/email/' + this.mail_id).then(res => {
                    res.data.forEach(d => d.active = true)

                    this.mails = res.data
                }).finally(() => {
                    this.loading = false
                }).catch(() => {
                    this.$notify.error('patient.emails.notify.error.LOAD')
                })
            },
            retryOfferClosed(mail_id) {
                if(mail_id) {
                    this.retry_offer = true

                    this.load(mail_id)
                }

                this.dialog.retry_offer = false
            },
            close() {
                this.$emit('close', this.retry_offer)
            }
        }
    }
</script>

<style lang="less" scoped>
    table#mail-table-header {
        margin-top: 10px;

        > tbody > tr > th {
            padding: 5px 20px 5px 0;
            text-align: right;
        }
    }

    #body {
        width: 100%;
        height: calc(100dvh - 250px);
        overflow: auto;

        &.panel-multi {
            height: calc(100dvh - 190px);
        }

        .panel.widget {
            margin-bottom: 0;
            border-radius: 0;

            &.bg-warning {
                color: #000 !important;

                i.fa, .link {
                    color: #000 !important;
                    font-weight: 400;
                }

                ul {
                    list-style-type: square;
                }
            }
        }

        > .panel-mail {
            width: 100%;

            > .panel-mail-header {
                display: flex;
                align-items: center;
                justify-content: space-between;

                background: #23b7e5;
                color: #000;

                padding: 5px 10px;
                cursor: pointer;

                > table > tbody > tr > th {
                    padding-right: 5px;
                }
            }

            > iframe {
                background: #fff;
                width: 100%;
            }

            &:not(:last-child) {
                border-bottom: 1px solid #eee;
            }
        }
    }

    @media only screen and (max-width: 991px) {
        #body {
            height: calc(100dvh - 315px);

            &.panel-multi {
                height: calc(100dvh - 230px);
            }
        }
    }
</style>