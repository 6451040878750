<template lang="pug">
    .row(v-if="statuses")
        .col-xs-12
            .col-xs-12(style="border-top: 1px solid;")
                .flex-wrap
                    h4 Statusy:
                    OptiButtonFilter(
                        :selected="statuses.all"
                        :title="'Wszystkie'"

                        @click="toggleAllStatuses"
                        style="margin-right: 10px"
                    )

                    OptiButtonFilter(
                        v-for="(key, index) in orderOfKeys"
                        :key="index"

                        @click="updateStatus(key)"

                        :selected="statuses[key]"
                        :title="key === 'not_selected' ? 'Brak' : key.toUpperCase()"
                        style="margin-right: 1px"
                    )

                    OptiSortFilter.pull-right(
                        :sorting="sorting"

                        @sorting="$emit('sorting')"
                    )
</template>

<script>

import OptiButtonFilter from '@/js/app/vue/components/Button/OptiButtonFilter.vue'
import OptiSortFilter from '@/js/app/vue/components/Catalog/Filters/OptiSortFilter.vue'

export default {
    name: 'OptiProductStatusFilter',
    components: {OptiSortFilter, OptiButtonFilter},
    props: {
        statuses: {
            type: Object,
            required: false
        },
        sorting: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            orderOfKeys: ['mh', 'pi1', 'pi', 'ac', 'po', 'not_selected']
        }
    },
    methods: {
        toggleAllStatuses() {
            this.statuses.all = !this.statuses.all
            this.$emit('allStatuses')
        },
        updateStatus(key) {
            this.statuses[key] = !this.statuses[key]
            key === 'not_selected' ? this.$emit('empty') : this.$emit('selectStatus', this.statuses[key])
        }
    }
}
</script>

<style scoped>

</style>