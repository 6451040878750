<template lang="pug">
    .row
        .col-sm-6.col-sm-offset-3
            .panel.panel-default
                .panel-heading
                    i.fa.fa-navicon.mr3
                    span {{'report.forecast.range.HEADING'|translate}}

                .panel-body(style="position:relative")
                    .row
                        .col-xs-12
                            OptiSelectQuarterOrSnapshot(
                                :quarter="quarter"
                                :week="week"

                                @select-weeks-was-loaded="loading.select = false")

                    OptiDimmer(:active="is_loading")

                .panel-footer.text-center
                    OptiButton(type="success" :disabled="is_loading" @click.native="getReport"
                        ) {{'report.discount.CALCULATE'|translate}}
                        template(#icon)
                            i.fa.fa-table
        .col-xs-12(style="margin-bottom:10px" v-show="range.from && !loading.main")
            .text-muted(style="display:inline-block;margin-top:12px")
                i.fa.fa-calendar.mr3
                span {{range.from}} - {{range.to}}

            OptiButton.pull-right(@click.native="exportXls") {{'report.tasksale.XLS'|translate}}
                template(#icon)
                    i.fa.fa-file-excel-o
        .col-xs-12
            .table-responsive(v-show="range.from && !loading.main")
                table.table.table-striped.middle
                    thead
                        tr.bg-separator
                            th {{'report.promotion.table.WITHOUTDISCOUNT'|translate}}
                            th {{'report.promotion.table.VALUE'|translate}}
                            th {{'report.promotion.table.DISCOUNT'|translate}}
                            th {{'report.promotion.table.PROMOTION'|translate}}
                            th {{'report.promotion.table.percent.PART'|translate}}
                            th {{'report.promotion.table.percent.DISCOUNT'|translate}}

                    tbody
                        tr(v-for="row in rows" :key="row.promotion_id")
                            td.text-right {{row.without_discount|currency}}
                            td.text-right {{row.value|currency}}
                            td.text-right {{row.without_discount - row.value|currency}}
                            td.bold {{row.promotion.name}}
                            td.text-right {{row.value * 100 / sum.value|number(2)}}
                            td.text-right {{(row.without_discount - row.value) * 100 / row.without_discount|number(2)}}

                        tr.text-center.text-muted(v-show="!rows.length")
                            td(colspan="100")
                                i.fa.fa-exclamation-circle.mr3
                                span {{'default.table.EMPTY'|translate}}

                    tfoot(v-show="sum.without_discount || sum.value")
                        tr.bg-separator
                            td.text-right {{sum.without_discount|currency}}
                            td.text-right {{sum.value|currency}}
                            td.text-right {{sum.without_discount - sum.value|currency}}
                            td(colspan="3")

            OptiDimmer(:active="loading.main")
</template>

<script>
    import {API} from '@/js/app/vue/api'

    import OptiSelectQuarterOrSnapshot from '@/js/app/vue/components/Select/OptiSelectQuarterOrSnapshot'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'
    import OptiButton from '@/js/app/vue/components/Button/OptiButton'

    export default {
        name: 'OptiReportPromotions',
        components: {
            OptiSelectQuarterOrSnapshot,
            OptiDimmer,
            OptiButton
        },
        data() {
            return {
                week: {
                    is_current: false,

                    from: {},
                    to: {}
                },
                quarter: {
                    from: {
                        q: null,
                        y: null
                    },
                    to: {
                        q: null,
                        y: null
                    }
                },

                range: {},
                rows: [],
                sum: {
                    without_discount: 0,
                    value: 0
                },

                loading: {
                    main: false,
                    select: true
                }
            }
        },
        methods: {
            getReport() {
                this.loading.main = true

                API.post('reports/promotions', this.week).then(res => {
                    this.range = res.data.range
                    this.rows = res.data.data

                    this.sum.without_discount = _.sumBy(this.rows, 'without_discount')
                    this.sum.value = _.sumBy(this.rows, 'value')
                }).finally(() => {
                    this.loading.main = false
                }).catch(() => {
                    this.range = {}
                    this.rows = []

                    this.sum.without_discount = 0
                    this.sum.value = 0

                    this.$notify.error('report.promotion.notify.error.LOAD')
                })
            },
            exportXls() {
                API.post('reports/promotions/export-xls', {
                    rows: this.rows,
                    sum: this.sum
                }).then(res => {
                    window.open(res.data)
                }).catch(() => {
                    this.$notify.error('report.promotion.notify.error.EXCEL')
                })
            }
        },
        computed: {
            is_loading() {
                return this.loading.main || this.loading.select
            }
        }
    }
</script>