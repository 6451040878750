<template lang="pug">
    table.table.table-presenter.table-responsive.table-striped
        tbody
            tr(v-show="showPdDist")
                th(rowspan="2") {{'prescription.PDDISTANCE'|translate}}
                td.text-center(colspan="2")
                    span.form-control(style="width:50%;margin:0 auto" disabled) {{pdDist|number(2)}}
            tr(v-show="showPdDist")
                td.text-center(style="line-height:1" :class="{'has-error': measurement_pd_dist_r}")
                    OptiNumber.text-center(:header="$filters.translate('prescription.PDDISTANCE') + ' - ' + $filters.translate('prescription.RIGHT')"
                        :value="rePdDist" @confirmed="v => update('rePdDist', v)" :float="true" :max="99"
                        :disabled="disabled" :pending="pending") {{rePdDist|number(2)}}
                    small.warn(v-show="measurement_pd_dist_r") {{'tasks.ipad.notify.MANUAL'|translate}}
                td.text-center(style="line-height:1" :class="{'has-error': measurement_pd_dist_l}")
                    OptiNumber.text-center(:header="$filters.translate('prescription.PDDISTANCE') + ' - ' + $filters.translate('prescription.LEFT')"
                        :value="lePdDist" @confirmed="v => update('lePdDist', v)" :float="true" :max="99"
                        :disabled="disabled" :pending="pending") {{lePdDist|number(2)}}
                    small.warn(v-show="measurement_pd_dist_l") {{'tasks.ipad.notify.MANUAL'|translate}}

            tr(v-show="showPdNear")
                th(rowspan="2") {{'prescription.PDNEAR'|translate}}
                td.text-center(colspan="2")
                    span.form-control(style="width:50%;margin:0 auto" disabled) {{pdNear|number(2)}}
            tr(v-show="showPdNear")
                td.text-center(style="line-height:1" :class="{'has-error': measurement_pd_near_r}")
                    OptiNumber.text-center(:header="$filters.translate('prescription.PDNEAR') + ' - ' + $filters.translate('prescription.RIGHT')"
                        :value="rePdNear" @confirmed="v => update('rePdNear', v)" :float="true" :max="99"
                        :disabled="disabled" :pending="pending") {{rePdNear|number(2)}}
                    small.warn(v-show="measurement_pd_near_r") {{'tasks.ipad.notify.MANUAL'|translate}}
                td.text-center(style="line-height:1" :class="{'has-error': measurement_pd_near_l}")
                    OptiNumber.text-center(:header="$filters.translate('prescription.PDNEAR') + ' - ' + $filters.translate('prescription.LEFT')"
                        :value="lePdNear" @confirmed="v => update('lePdNear', v)" :float="true" :max="99"
                        :disabled="disabled" :pending="pending") {{lePdNear|number(2)}}
                    small.warn(v-show="measurement_pd_near_l") {{'tasks.ipad.notify.MANUAL'|translate}}

            tr(v-show="showBt")
                th
                    span(v-show="taskType !== 3") {{'tasks.BT'|translate}}
                    span(v-show="taskType === 3") {{'tasks.BTBIFOCAL'|translate}}
                td.text-center(style="line-height:1" :class="{'has-error': measurement_bt_r}")
                    OptiNumber.text-center(:header="$filters.translate(taskType === 3 ? 'tasks.BTBIFOCAL' : 'tasks.BT') + ' - ' + $filters.translate('prescription.RIGHT')"
                        :value="reBt" @confirmed="v => update('reBt', v)" :float="true"
                        :disabled="disabled" :pending="pending") {{reBt|number(2)}}
                    small.warn(v-show="measurement_bt_r") {{'tasks.ipad.notify.MANUAL'|translate}}
                td.text-center(style="line-height:1" :class="{'has-error': measurement_bt_l}")
                    OptiNumber.text-center(:header="$filters.translate(taskType === 3 ? 'tasks.BTBIFOCAL' : 'tasks.BT') + ' - ' + $filters.translate('prescription.LEFT')"
                        :value="leBt" @confirmed="v => update('leBt', v)" :float="true"
                        :disabled="disabled" :pending="pending") {{leBt|number(2)}}
                    small.warn(v-show="measurement_bt_l") {{'tasks.ipad.notify.MANUAL'|translate}}
            tr(v-show="showBt && taskType === 3 && !disabled")
                td.text-center.warn(colspan="3")
                    i.fa.fa-exclamation-circle.mr3
                    span {{'tasks.measurement.SEGMENT'|translate}}
</template>

<script>
    import OptiNumber from '@/js/app/vue/components/Inputs/OptiNumber'

    export default {
        name: 'OptiPdCalculate',
        components: {
            OptiNumber
        },
        props: {
            measurement: {
                type: Object | Array,
                required: false,
                default: () => {
                    return {}
                }
            },
            taskType: {
                type: Number,
                required: false,
                default: 0
            },

            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            pending: {
                type: Boolean,
                required: false,
                default: false
            },

            showPdDist: {
                type: Boolean,
                required: true
            },
            pdDist: {
                type: Number,
                required: true
            },
            rePdDist: {
                type: Number,
                required: true
            },
            lePdDist: {
                type: Number,
                required: true
            },

            showPdNear: {
                type: Boolean,
                required: true
            },
            pdNear: {
                type: Number,
                required: true
            },
            rePdNear: {
                type: Number,
                required: true
            },
            lePdNear: {
                type: Number,
                required: true
            },

            showBt: {
                type: Boolean,
                required: false,
                default: false
            },
            reBt: {
                type: Number,
                required: false,
                default: 0
            },
            leBt: {
                type: Number,
                required: false,
                default: 0
            }
        },
        data() {
            return {
                updated: {}
            }
        },

        methods: {
            distToNear(pdDist, rePdDist, lePdDist) {
                let pdNear = pdDist - 4,
                    rePdNear = rePdDist - 2,
                    lePdNear = lePdDist - 2

                if(pdNear > 0) {
                    this.updated.pdNear = this.round(pdNear)
                }

                if(rePdNear > 0) {
                    this.updated.rePdNear = this.round(rePdNear)
                }

                if(rePdNear > 0) {
                    this.updated.lePdNear = this.round(lePdNear)
                }
            },
            nearToDist(pdNear, rePdNear, lePdNear) {
                let pdDist = pdNear + 4,
                    rePdDist = rePdNear + 2,
                    lePdDist = lePdNear + 2

                if(pdDist > 0) {
                    this.updated.pdDist = this.round(pdDist)
                }

                if(rePdDist > 0) {
                    this.updated.rePdDist = this.round(rePdDist)
                }

                if(rePdDist > 0) {
                    this.updated.lePdDist = this.round(lePdDist)
                }
            },
            update(field, value) {
                if(this[field] !== value) {
                    this.updated = {
                        [field]: value
                    }

                    switch(field) {
                        case 'rePdDist':
                            this.updated.pdDist = this.round(value + this.lePdDist)

                            this.distToNear(this.updated.pdDist, value, this.lePdDist)
                            break

                        case 'lePdDist':
                            this.updated.pdDist = this.round(this.rePdDist + value)

                            this.distToNear(this.updated.pdDist, this.rePdDist, value)
                            break

                        case 'rePdNear':
                            this.updated.pdNear = this.round(this.lePdNear + value)

                            this.nearToDist(this.updated.pdNear, value, this.lePdNear)
                            break

                        case 'lePdNear':
                            this.updated.pdNear = this.round(this.rePdNear + value)

                            this.nearToDist(this.updated.pdNear, this.rePdNear, value)
                    }

                    this.$emit('updated', this.updated)
                }
            },
            round(float) {
                return Math.round(float * 100) / 100
            }
        },
        computed: {
            measurement_pd_dist_r() {
                return this.measurement.id &&
                    (typeof this.measurement.pdR !== 'undefined' ? this.measurement.pdR : this.measurement.pd_r) !== this.rePdDist
            },
            measurement_pd_dist_l() {
                return this.measurement.id &&
                    (typeof this.measurement.pdL !== 'undefined' ? this.measurement.pdL : this.measurement.pd_l) !== this.lePdDist
            },

            measurement_pd_near_r() {
                return this.measurement.id &&
                    (typeof this.measurement.pdNearR !== 'undefined' ? this.measurement.pdNearR : this.round(this.measurement.pd_r - 2)) !== this.rePdNear
            },
            measurement_pd_near_l() {
                return this.measurement.id &&
                    (typeof this.measurement.pdNearL !== 'undefined' ? this.measurement.pdNearL : this.round(this.measurement.pd_l - 2)) !== this.lePdNear
            },

            measurement_bt_r() {
                return this.measurement.id &&
                    this.round((typeof this.measurement.btR !== 'undefined' ? this.measurement.btR : this.measurement.bt_r) -
                        (this.taskType === 1 ? 2 : 0)) !== this.reBt
            },
            measurement_bt_l() {
                return this.measurement.id &&
                    this.round((typeof this.measurement.btL !== 'undefined' ? this.measurement.btL : this.measurement.bt_l) -
                        (this.taskType === 1 ? 2 : 0)) !== this.leBt
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .modal-container {
        max-width: 350px;
    }
</style>