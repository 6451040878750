<template lang="pug">
    div(style="padding-right:10px;padding-left:10px"
        :class="{'col-xs-9': !cardActive.summary, 'col-lg-10': !cardActive.summary, 'col-xs-12': cardActive.summary}")
        nav(style="position:relative" :class="{'is-offer': !!offer}")
            ul.nav.nav-tabs.nav-justified.icons-header-tab
                li(:class="{'active': cardActive.summary}" v-if="showSummary")
                    a(:title="tab.summary|translate" @click="$emit('set-active', 'summary')")
                        i.visible-xs-inline.fa-lg.fa.fa-sliders
                        i.hidden-xs.fa.fa-sliders
                        span.visible-xs-inline.tab-title {{tab.summary|translate}}
                li(:class="{'active': cardActive.frame}")
                    a(:title="tab.frame|translate" @click="$emit('set-active', 'frame')")
                        i.visible-xs-inline.fa-lg.fa.fa-crop
                        i.hidden-xs.fa.fa-crop
                        span.visible-xs-inline.tab-title {{tab.frame|translate}}
                li.lenses-main(:class="{'active': cardActive.lenses}")
                    a(:title="tab.lenses|translate" @click="$emit('set-active', 'lenses')")
                        i.visible-xs-inline.fa-lg.fa.fa-eye
                        i.hidden-xs.fa.fa-eye
                        span.visible-xs-inline.tab-title {{tab.lenses|translate}}
                        i.fa.fa-star.ml3(style="color:orange;font-size:20px" v-if="!!offer")
                li(:class="{'active': cardActive.lenses_to_compare}" v-if="!!offer")
                    a(:title="tab.lenses_to_compare|translate" @click="$emit('set-active', 'lenses_to_compare')")
                        i.visible-xs-inline.fa-lg.fa.fa-eye
                        i.hidden-xs.fa.fa-eye
                        span.visible-xs-inline.tab-title {{tab.lenses_to_compare|translate}}
                li(:class="{'active': cardActive.payment}")
                    a(:title="tab.payment|translate" @click="$emit('set-active', 'payment')")
                        i.visible-xs-inline.fa-lg.fa.fa-money
                        i.hidden-xs.fa.fa-money
                        span.visible-xs-inline.tab-title {{tab.payment|translate}}
                li(:class="{'active': cardActive.previouslenses}")
                    a(:title="tab.previouslenses|translate" @click="$emit('set-active', 'previouslenses')")
                        i.visible-xs-inline.fa-lg.fa.fa-history
                        i.hidden-xs.fa.fa-history
                        span.visible-xs-inline.tab-title {{tab.previouslenses|translate}}
                        span.badge.ml3(v-show="task.patient.previouslenses.length + task.patient.contactlenses.length"
                            ) {{task.patient.previouslenses.length + task.patient.contactlenses.length}}
                li.ipad(:class="{'active': cardActive.ipad}")
                    a(:title="tab.ipad|translate" @click="$emit('set-active', 'ipad')")
                        i.visible-xs-inline.fa-lg.fa.fa-tablet
                        i.hidden-xs.fa.fa-tablet
                        span.visible-xs-inline.tab-title {{tab.ipad|translate}}
                        i.fa.fa-star.ml3(style="color:orange;font-size:20px" v-if="!!offer")
                li(:class="{'active': cardActive.history}")
                    a(:title="tab.history|translate" @click="$emit('set-active', 'history')")
                        i.visible-xs-inline.fa-lg.fa.fa-book
                        i.hidden-xs.fa.fa-book
                        span.visible-xs-inline.tab-title {{tab.history|translate}}
                        i.fa.fa-exclamation-circle.text-warning.ml3(style="font-size:25px" v-show="changeLogs.info")
                li(:class="{'active': cardActive.notes}")
                    a(:title="tab.notes|translate" @click="$emit('set-active', 'notes')")
                        i.visible-xs-inline.fa-lg.fa.fa-comments
                        i.hidden-xs.fa.fa-comments
                        span.visible-xs-inline.tab-title {{tab.notes|translate}}
                        span.badge.ml3(v-show="notesLength") {{notesLength}}

            .tab-content
                OptiTaskDetailsSummary.tab-pane(:class="{'active': cardActive.summary}"
                    :task="task"
                    :rate-save="rateSave"
                    :offer="offer"

                    :task-duplicate="taskDuplicate"
                    :task-service="taskService"
                    :increment="increment"
                    :saturation-translate="saturationTranslate"
                    :task-extra-us-info="taskExtraUsInfo"
                    :discount-percent-with-shipping="discountPercentWithShipping"
                    :marketing="marketing"
                    :notice="notice"
                    :valid="valid"

                    :block="block"
                    :allow-edit-confirmed="allowEditConfirmed"

                    @set-active="t => $emit('set-active', t)"
                    @go-done="goDone"
                    @summary-was-updated="$emit('save')"
                    @rate-save="$emit('rate-save')")

                OptiTaskDetailsFrame.tab-pane(:class="{'active': cardActive.frame}"
                    :task="task"
                    :is-offer="!!offer"

                    :shape="shape"

                    :put-back="putBack"
                    :for-sc="forSc"
                    :task-duplicate="taskDuplicate"
                    :task-service="taskService"

                    :block="block"
                    :pending="pending"
                    :allow-edit-confirmed="allowEditConfirmed"

                    @frame-was-updated="$emit('save')")

                OptiTaskDetailsLenses.tab-pane(:class="{'active': cardActive.lenses}"
                    :task="task"
                    :is-offer="!!offer"
                    :offer-hoya-option="offerHoyaOption"
                    :shape="shape"
                    :extension="extension"
                    :increment="increment"

                    :block="block"
                    :allow-edit-confirmed="allowEditConfirmed"
                    :pending="pending"

                    @pending="p => $emit('pending', p)"
                    @increment-up="$emit('increment-up')"
                    @register-change="$emit('register-change')"
                    @frame-overwrite-was-updated="p => $emit('frame-overwrite-was-updated', p)"
                    @lenses-was-updated="$emit('save')")

                OptiTaskDetailsLenses.tab-pane(:class="{'active': cardActive.lenses_to_compare}"
                    v-if="!!offer"

                    :task="task"
                    :is-offer="!!offer"
                    :is-compare="true"
                    :offer-hoya-option="offerHoyaOption"
                    :shape="shape"
                    :extension="extension"
                    :increment="increment"

                    :block="block"
                    :allow-edit-confirmed="allowEditConfirmed"
                    :pending="pending"

                    @pending="p => $emit('pending', p)"
                    @increment-up="$emit('increment-up')"
                    @register-change="$emit('register-change')"
                    @frame-overwrite-was-updated="p => $emit('frame-overwrite-was-updated', p)"
                    @lenses-was-updated="$emit('save')")

                OptiTaskDetailsPayment.tab-pane(:class="{'active': cardActive.payment}"
                    :task="task"
                    :is-offer="!!offer"

                    :marketing="marketing"
                    :notice="notice"
                    :valid="valid"
                    :saturation-translate="saturationTranslate"
                    :task-extra-us-info="taskExtraUsInfo"
                    :discount-percent-with-shipping="discountPercentWithShipping"

                    :put-back="putBack"
                    :for-sc="forSc"

                    :block="block"
                    :pending="pending"

                    @invalid="setValid"
                    @pending="p => $emit('update-payment-pending', p)"

                    @go-done="goDone"
                    @payment-was-updated="$emit('save')")

                OptiPreviousLenses.tab-pane(:class="{'active': cardActive.previouslenses}"
                    mode="task"
                    :data="task.patient.previouslenses"
                    :contact-lenses="task.patient.contactlenses"
                    :patient-id="task.patient.id"
                    :department-id="task.departmentId"
                    :measurement-app="task.patient.department.measurement_app"
                    :checked="task.previous_glasses_checked"
                    :checked-contact="task.previous_contacts_checked"
                    :glasses="task.glasses"
                    :disabled="block || pending"

                    @update-previous-lenses="updatePreviousLenses"
                    @update-previous-lenses-contact="updatePreviousLenses"

                    @update-glasses-checkbox="$emit('save')")

                OptiTaskDetailsIpad.tab-pane(:class="{'active': cardActive.ipad}"
                    :task="task"

                    :is-offer="!!offer"

                    :task-duplicate="taskDuplicate"
                    :task-service="taskService"

                    :block="block"
                    :pending="pending"

                    @ipad-was-updated="$emit('save')")

                OptiTaskDetailsLogs.tab-pane(:class="{'active': cardActive.history}"
                    :change-logs="changeLogs"

                    :is-offer="!!offer")

                OptiTaskDetailsNotes.tab-pane(:class="{'active': cardActive.notes}"
                    :task="task"

                    :is-offer="!!offer"

                    @update-notes-length="n => $emit('update-notes-length', n)")

            OptiDimmer.opti-dimmer-task(:active="pending")
</template>

<script>
    import {ReceiptNotice} from '@/js/app/vue/helpers/ReceiptNotice'
    import validateTask from '@/js/app/vue/components/Tasks/TaskValidator'

    import OptiTaskDetailsSummary from '@/js/app/vue/components/Tasks/Details/OptiTaskDetailsSummary'
    import OptiTaskDetailsFrame from '@/js/app/vue/components/Tasks/Details/OptiTaskDetailsFrame'
    import OptiTaskDetailsLenses from '@/js/app/vue/components/Tasks/Details/OptiTaskDetailsLenses'
    import OptiTaskDetailsPayment from '@/js/app/vue/components/Tasks/Details/OptiTaskDetailsPayment'
    import OptiPreviousLenses from '@/js/app/vue/components/PreviousLenses/OptiPreviousLenses'
    import OptiTaskDetailsIpad from '@/js/app/vue/components/Tasks/Details/OptiTaskDetailsIpad'
    import OptiTaskDetailsLogs from '@/js/app/vue/components/Tasks/Details/OptiTaskDetailsLogs'
    import OptiTaskDetailsNotes from '@/js/app/vue/components/Tasks/Details/OptiTaskDetailsNotes'
    import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiTaskDetails',
        components: {
            OptiTaskDetailsSummary,
            OptiTaskDetailsFrame,
            OptiTaskDetailsLenses,
            OptiTaskDetailsPayment,
            OptiPreviousLenses,
            OptiTaskDetailsIpad,
            OptiTaskDetailsLogs,
            OptiTaskDetailsNotes,
            OptiDimmer
        },
        props: {
            tab: {
                type: Object,
                required: true
            },
            cardActive: {
                type: Object,
                required: true
            },
            showSummary: {
                type: Boolean,
                required: true
            },

            task: {
                type: Object,
                required: true
            },
            rateSave: {
                required: true
            },
            offer: {
                required: true
            },

            changeLogs: {
                type: Object,
                required: true
            },
            notesLength: {
                type: Number,
                required: true
            },

            increment: {
                type: Number,
                required: true
            },
            marketing: {
                type: Object,
                required: true
            },

            pending: {
                type: Boolean,
                required: true
            },

            extension: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                saturationTranslate: {
                    Amber: 'Bursztyn',
                    Gradient: 'Gradalny',
                    Solid: 'Pełny',
                    Fashion: 'Fashion'
                },
                shape: {
                    direction: {
                        frameInfo: '',
                        top: false,
                        bottom: false,
                        temporal: false,
                        nasal: false
                    }
                },
                notice: {
                    account: null,
                    rest: null
                },
                valid: {
                    deposit: false,
                    rest: false,
                    bill: false
                },

                first_time_save_previous_lenses: true
            }
        },
        mounted() {
            ReceiptNotice.get('deposit', this.task.id).then(res => this.notice.account = res).catch(() => {})
            ReceiptNotice.get('rest', this.task.id).then(res => this.notice.rest = res).catch(() => {})
        },
        methods: {
            setValid(type, invalid) {
                this.valid[type] = !invalid
            },
            updatePreviousLenses() { //TODO: sprawdzić dlaczego poprzednie okulary robią pustego save'a
                if(!this.first_time_save_previous_lenses) {
                   this.$emit('save')
                }

                this.first_time_save_previous_lenses = false
            },
            goDone() {
                let warnings = validateTask(this.task, 'all')

                if(!warnings.length) {
                    if(confirm(this.$filters.translate('tasks.action.received.confirm.MESSAGE'))) {
                        this.task.status = 10

                        this.$emit('save')
                    }
                } else {
                    warnings.forEach(warning => {
                        this.$notify.error(warning)
                    })
                }
            }
        },
        computed: {
            block() {
                return this.task.status !== 1
            },
            allowEditConfirmed() {
                // to samo w TasksSaveController.php:151, TasksChangeController.php:889

                return !this.block || (this.task.status === 3 && this.task.order_by_admin && this.$uac.permission('tasks.order_by_admin'))
            },

            putBack() {
                return Object.keys(this.task.attributes).includes('putBack') ||
                    Object.keys(this.task.attributes).includes('greenPutBack')
            },
            forSc() {
                return Object.keys(this.task.attributes).includes('forSc')
            },
            taskDuplicate() {
                return Object.keys(this.task.attributes).includes('taskDuplicate')
            },
            taskService() {
                return Object.keys(this.task.attributes).includes('service')
            },

            taskExtraUsInfo() {
                return !!this.task.services.filter(s => s.id !== 1 && !s.us).length
            },
            discountPercentWithShipping() {
                return this.task.registry.discountType === 'percent' &&
                    !!this.task.services.filter(s => [7, 8, 9].includes(s.id)).length
            },

            offerHoyaOption() {
                if(this.offer) {
                    let offer_product_pivot = this.offer.products.filter(p => p.pivot.tasks.map(t => t.offer_temporary_task_id).includes(this.task.id))[0].pivot

                    return offer_product_pivot.hoya_option_id ? offer_product_pivot.hoya_option : null
                }

                return null
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .opti-dimmer-task {
        z-index: 100 !important;

        > .loader > .fa {
            font-size: 150px;
        }
    }
</style>
